import { useContext, useEffect, useState } from 'react'
import Page from '../../../../../components/Page'
import { t } from 'i18next'
import { NavigationStoreContext } from '../../../../../store/navigationStore'
import { KidsStoreContext } from '../../../../../store/kidsStore'
import { UserStoreContext } from '../../../../../store/userStore'
import { DepartmentStoreContext } from '../../../../../store/departmentStore'
import { getImageOrPlaceHolder } from '../../../../../utils/parseUtils'
import { Kid } from '../../../../../Models/Kid'
import { useParams } from 'react-router-dom'
import { Loading } from '../../../../../components/Loading'
import React from 'react'
import KidListCardHorizontal from '../../../../../components/kid/KidListCardHorizontal'
import { observer } from 'mobx-react'
import { Button } from '../../../../../components/Button'
import { USER_ROLE_STATUS } from '../../../../../Enums/TyraEnums'
import CustomRadioButton from '../../../../../components/CustomRadioButton'
import {
  NewUser,
  RELATION_AND_AUTORITHY,
  TYRA_RELATION_NEW,
} from '../InviteParents'
import classNames from 'classnames'
import CustomInput from '../../../../../components/CustomInput'
import CustomInputNew from '../../../../../components/CustomInputNew'
import PhoneInput from 'react-phone-input-2'
interface Props {
  onlyRelation: boolean
  onChangeRelation: (value: RELATION_AND_AUTORITHY) => void
  relationAndAuthority: RELATION_AND_AUTORITHY
  otherText: string
  editFreeTextValue: (value: string) => void
  onDone: () => void
  newUserInfo?: NewUser
  setNewUserInfo?: (userInfo: NewUser) => void
}
export const InformationAboutParent: React.FC<Props> = observer(
  ({
    onlyRelation,
    onChangeRelation,
    relationAndAuthority,
    otherText,
    editFreeTextValue,
    onDone,
    newUserInfo,
    setNewUserInfo,
  }) => {
    const [showInviteModal, setShowInviteModal] = useState(false)
    const { fetchKids, kids } = useContext(KidsStoreContext)
    const { currentUserRole, fetchUserRolesToInviteToKid, fetchNewUserRoles } =
      useContext(UserStoreContext)
    const { departments, fetchDepartments } = useContext(DepartmentStoreContext)
    const { setTitle } = useContext(NavigationStoreContext)

    const { schoolId } = useParams()
    const [loading, setLoading] = useState(false)

    useEffect(() => {
      setTitle(t('admin.inviteParent'))
    }, [t])

    const options = [
      {
        value: TYRA_RELATION_NEW.PARENT,
        label: '!!Vårdnadshavare',
        text: '',
      },
      {
        value: TYRA_RELATION_NEW.MOM,
        label: '!!Mamma',
        text: '',
      },
      {
        value: TYRA_RELATION_NEW.DAD,
        label: '!!Pappa',
        text: '',
      },
      {
        value: TYRA_RELATION_NEW.TRUSTEE,
        label: '!!Förvaltare',
        text: '',
      },
      {
        value: TYRA_RELATION_NEW.OTHER,
        label: '!!Annat',
        text: '',
        freeText: true,
      },
    ]

    console.log('newUser', newUserInfo)
    return (
      <>
        {loading && <Loading />}
        <div
          className={classNames(
            onlyRelation ? 'm-auto grid max-w-xl' : 'grid grid-cols-2 gap-x-4',
          )}
        >
          {!onlyRelation && (
            <div className="flex flex-col w-full gap-y-2">
              <div className="flex justify-center text-xl font-bold">
                !!Ange persondata
              </div>
              <div className="flex justify-center text-center text-xs">
                !!Denna information sparas på inbjudan.
                <br />
                !!När användaren skapar sitt konto kommer denna informationen.
              </div>
              <div className="flex flex-col gap-y-4 mt-4">
                <div className="w-full">
                  {/*<input
                    className="grow flex-1 block w-full px-4 py-3 placeholder-gray-400 border border-white rounded-xl shadow-sm appearance-none focus:outline-none focus:ring-white focus:border-white sm:text-sm"
                    placeholder="!!Personnummer"
                    value={''}
                    onChange={(e) => {}}
          />*/}
                  <CustomInputNew
                    onChange={(value) => {
                      if (setNewUserInfo) {
                        setNewUserInfo({ ...newUserInfo, pn: value })
                      }
                    }}
                    placeholder="Personnummer"
                    longPlaceholder="Ange ett personnummer"
                    label="!!Personnummer"
                    value={newUserInfo?.pn}
                    format="YYYYMMDD-LLXX"
                  />
                </div>
                <div className="w-full text-sm">
                  !!<b>Rekommenderas</b>, !!så at användaren loggar in med
                  BankID, säkert och enkelt
                </div>
                <div className="w-full">
                  <CustomInputNew
                    onChange={(value) => {
                      if (setNewUserInfo) {
                        setNewUserInfo({ ...newUserInfo, firstName: value })
                      }
                    }}
                    placeholder="Förnamn"
                    longPlaceholder="Skriv förnamnet"
                    label="!!Förnamn"
                    value={newUserInfo?.firstName}
                    format="default"
                    required
                  />
                </div>
                <div className="w-full">
                  <CustomInputNew
                    onChange={(value) => {
                      if (setNewUserInfo) {
                        setNewUserInfo({ ...newUserInfo, lastName: value })
                      }
                    }}
                    placeholder="Efternamn"
                    longPlaceholder="Skriv efternamnet"
                    label="!!Förnamn"
                    value={newUserInfo?.lastName}
                    format="default"
                  />
                </div>
                <div className="w-full">
                  <CustomInputNew
                    onChange={(value) => {
                      if (setNewUserInfo) {
                        setNewUserInfo({ ...newUserInfo, email: value })
                      }
                    }}
                    placeholder="Email"
                    longPlaceholder="Skriv emailadressen"
                    label="!!Förnamn"
                    value={newUserInfo?.email}
                    format="default"
                  />
                </div>
                <div className="w-full">
                  <CustomInputNew
                    onChange={(value) => {
                      if (setNewUserInfo) {
                        setNewUserInfo({ ...newUserInfo, phone: value })
                      }
                    }}
                    placeholder="Telefonnummer"
                    longPlaceholder="Ange ett telefonnummer"
                    label="!!Förnamn"
                    value={newUserInfo?.phone}
                    format="phone"
                  />
                </div>
              </div>
            </div>
          )}
          <div className="flex flex-col w-full justify-center gap-y-2">
            <div className="text-xl flex justify-center font-bold">
              !!Relation och behörighet
            </div>
            <CustomRadioButton
              options={options}
              onChange={(value) => {
                onChangeRelation({ ...relationAndAuthority, relation: value })
              }}
              value={relationAndAuthority.relation}
              freeTextValue={otherText}
              editFreeTextValue={(value: string) => editFreeTextValue(value)}
            />
            <CustomRadioButton
              options={[
                {
                  value: 'addDocuments',
                  label: '',
                  text: '!!Kunna lägga upp dokument i skolans mapp, samt lägga till Händelser',
                  slider: true,
                },
              ]}
              onChange={(value) => {
                onChangeRelation({
                  ...relationAndAuthority,
                  addDocuments: !relationAndAuthority.addDocuments,
                })
              }}
              value={
                relationAndAuthority.addDocuments === true ? 'true' : 'false'
              }
            />
            <CustomRadioButton
              options={[
                {
                  value: 'addSchemaTimesToOnlyStaff',
                  label: '',
                  text: '!!Kunna lägga in schematider som endast syns för personal och ej övriga vårdnadshavare',
                  slider: true,
                },
              ]}
              onChange={(value) => {
                onChangeRelation({
                  ...relationAndAuthority,
                  addSchemaTimesToOnlyStaff:
                    !relationAndAuthority.addSchemaTimesToOnlyStaff,
                })
              }}
              value={
                relationAndAuthority.addSchemaTimesToOnlyStaff === true
                  ? 'true'
                  : 'false'
              }
            />
            <CustomRadioButton
              options={[
                {
                  value: 'addNotesToOnlyStaff',
                  label: '',
                  text: '!!Kunna lägga in schematider som endast syns för personal och ej övriga vårdnadshavare',
                  slider: true,
                },
              ]}
              onChange={(value) => {
                console.log('value', value)
                onChangeRelation({
                  ...relationAndAuthority,
                  addNotesToOnlyStaff:
                    !relationAndAuthority.addNotesToOnlyStaff,
                })
              }}
              value={
                relationAndAuthority.addNotesToOnlyStaff === true
                  ? 'true'
                  : 'false'
              }
            />

            <Button
              className="mt-8"
              label="!!Fortsätt"
              title="!!Fortsätt"
              variant="fullBlue"
              disabled={
                relationAndAuthority.relation === TYRA_RELATION_NEW.OTHER &&
                otherText === ''
                  ? true
                  : false
              }
              onClick={() => {
                onDone()
              }}
            />
          </div>
        </div>
      </>
    )
  },
)
