import React, { useContext, useEffect, useState } from 'react'
import Page from '../../../../components/Page'
import ColorCard from '../../../../components/ColorCard'
import { t } from 'i18next'
import InviteHandleCard from './components/InviteHandleCard'
import { faUserPlus } from '@fortawesome/free-solid-svg-icons'
import {
  faUserCheck,
  faUserClock,
  faUserSlash,
  faUsers,
} from '@fortawesome/pro-solid-svg-icons'
import { useNavigate, useParams } from 'react-router-dom'
import { NavigationStoreContext } from '../../../../store/navigationStore'
import { UserStoreContext } from '../../../../store/userStore'
import { observer } from 'mobx-react'

const InviteHandleDashboard: React.FC<{ onClick?: any }> = observer(
  ({ onClick }) => {
    const navigate = useNavigate()
    const { schoolId } = useParams()
    const { setTitle } = useContext(NavigationStoreContext)
    const { currentUserRole } = useContext(UserStoreContext)
    useEffect(() => {
      setTitle(t('admin.inviteHandleUser'))
    }, [t])
    console.log(currentUserRole)

    return (
      <Page naked>
        {process.env.REACT_APP_PUBLIC_URL?.includes('localhost') ||
        process.env.REACT_APP_PUBLIC_URL?.includes('alfa') ? (
          <>
            {!currentUserRole?.school_pointer.isSs12kSchool && (
              <ColorCard
                bgColor="bg-pink-200 p-4 justify-center flex"
                title={t('admin.inviteUser')}
              >
                <div className="flex flex-wrap justify-center gap-4 pb-2">
                  {currentUserRole?.school_pointer.isSs12kSchool ? (
                    <InviteHandleCard
                      className="hover:bg-green-50"
                      title={t('admin.inviteStaff')}
                      iconClassName="text-green-500"
                      icon={faUserPlus}
                      onClick={() => {
                        navigate(`/admin/${schoolId}/invitePersonal`)
                        if (onClick) {
                          onClick()
                        }
                      }}
                    />
                  ) : (
                    <InviteHandleCard
                      className="hover:bg-green-50cursor-not-allowed opacity-60"
                      title={t('admin.inviteStaff')}
                      iconClassName="text-green-500"
                      icon={faUserPlus}
                      onClick={() => {
                        //TODO: Swal with text to inform to do it in the app instead
                      }}
                    />
                  )}

                  <InviteHandleCard
                    className="hover:bg-purple-50"
                    title={t('admin.inviteParent')}
                    iconClassName="text-purple-500"
                    icon={faUserPlus}
                    onClick={() => {
                      navigate(`/admin/${schoolId}/inviteParents`)
                      if (onClick) {
                        onClick()
                      }
                    }}
                  />
                </div>
              </ColorCard>
            )}
          </>
        ) : null}
        <ColorCard
          bgColor="bg-pink-200 p-4 justify-center flex"
          title={t('admin.handleUser')}
        >
          <div className="flex flex-wrap justify-center gap-4 p-2 lg:mx-20">
            <InviteHandleCard
              className="hover:bg-blue-50"
              title={t('admin.invitedUsers')}
              iconClassName="text-blue-500"
              icon={faUsers}
              onClick={() => {
                navigate(`/admin/${schoolId}/invitedUsers`)
                if (onClick) {
                  onClick()
                }
              }}
            />
            <InviteHandleCard
              className="hover:bg-orange-50"
              title={t('admin.waitingAccept')}
              iconClassName="text-orange-500"
              icon={faUserClock}
              onClick={() => {
                navigate(`/admin/${schoolId}/waitingApproval`)
                if (onClick) {
                  onClick()
                }
              }}
            />
            <InviteHandleCard
              className="hover:bg-lime-50"
              title={t('admin.activeUsers')}
              iconClassName="text-lime-500"
              icon={faUserCheck}
              onClick={() => {
                navigate(`/admin/${schoolId}/activeUsers`)
                if (onClick) {
                  onClick()
                }
              }}
            />
            <InviteHandleCard
              className="hover:bg-gray-50"
              title={t('admin.pausedUsers')}
              iconClassName="text-gray-300"
              icon={faUserSlash}
              onClick={() => {
                navigate(`/admin/${schoolId}/pausedUsers`)
                if (onClick) {
                  onClick()
                }
              }}
            />
          </div>
        </ColorCard>
      </Page>
    )
  },
)
export default InviteHandleDashboard
