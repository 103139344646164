import { useContext, useEffect, useState } from 'react'
import Page from '../../../../components/Page'
import { t } from 'i18next'
import { NavigationStoreContext } from '../../../../store/navigationStore'
import { KidsStoreContext } from '../../../../store/kidsStore'
import { UserStoreContext } from '../../../../store/userStore'
import { DepartmentStoreContext } from '../../../../store/departmentStore'
import { Kid } from '../../../../Models/Kid'
import { useParams } from 'react-router-dom'
import { Loading } from '../../../../components/Loading'
import React from 'react'
import KidListCardHorizontal from '../../../../components/kid/KidListCardHorizontal'
import { observer } from 'mobx-react'
import { ChooseOrCreateParent } from './components/ChooseOrCreateParent'
import { ChooseExistingUser } from './components/ChooseExistingUser'
import { UserRole } from '../../../../Models/UserRole'
import { InformationAboutParent } from './components/InformationAboutParent'
import ModalRegular from '../../../../components/ModalRegular'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faAt,
  faLocation,
  faLocationDot,
  faPhone,
  faUser,
} from '@fortawesome/free-solid-svg-icons'
import { Button } from '../../../../components/Button'
import { AdminStoreContext } from '../../../../store/adminStore'

export enum TYRA_RELATION_NEW {
  MOM = 'Mamma',
  DAD = 'Pappa',
  PARENT = 'Vårdnadshavare',
  TRUSTEE = 'Förvaltare',
  OTHER = 'Annat',
}
export interface RELATION_AND_AUTORITHY {
  relation: TYRA_RELATION_NEW
  addDocuments: boolean
  addSchemaTimesToOnlyStaff: boolean
  addNotesToOnlyStaff: boolean
}

export interface NewUser {
  pn?: string
  firstName?: string
  lastName?: string
  email?: string
  phone?: string
  streetAddress?: string
  postalCode?: string
  city?: string
}
const newUserInitialValues = {
  pn: undefined,
  firstName: undefined,
  lastName: undefined,
  email: undefined,
  phone: undefined,
  streetAddress: undefined,
  postalCode: undefined,
  city: undefined,
}

export const InviteParents: React.FC = observer(() => {
  const [showInviteModal, setShowInviteModal] = useState(false)
  const { fetchKids, kids } = useContext(KidsStoreContext)
  const { currentUserRole, fetchUserRolesToInviteToKid, fetchNewUserRoles } =
    useContext(UserStoreContext)
  const { departments, fetchDepartments } = useContext(DepartmentStoreContext)
  const { setTitle } = useContext(NavigationStoreContext)

  const { inviteExistingGuardian, inviteGuardian, inviteTeacher } =
    useContext(AdminStoreContext)

  const [selectedUserRole, setSelectedUserRole] = useState<
    UserRole | undefined
  >(undefined)

  const { schoolId } = useParams()
  const [loading, setLoading] = useState(false)
  useEffect(() => {
    if (currentUserRole && schoolId) {
      setLoading(true)
      fetchKids(currentUserRole).finally(() => {
        setLoading(false)
      })
      fetchDepartments(schoolId, true)
      //fetchUserRoles(currentUserRole)
      fetchUserRolesToInviteToKid(schoolId)
      fetchNewUserRoles(schoolId).finally(() => {})
    }
  }, [schoolId])
  useEffect(() => {
    setTitle(t('admin.inviteParent'))
  }, [t])
  const [selectedKid, setSelectedKid] = useState<Kid | undefined>()
  const [chosenInviteType, setChosenInviteType] = useState<
    'existing' | 'new' | undefined
  >(undefined)
  const [relationAndAuthority, setRelationAndAuthority] =
    useState<RELATION_AND_AUTORITHY>({
      relation: TYRA_RELATION_NEW.PARENT,
      addDocuments: false,
      addSchemaTimesToOnlyStaff: false,
      addNotesToOnlyStaff: false,
    })
  const [otherText, setOtherText] = useState('')
  const [showSummary, setShowSummary] = useState(false)
  const [newUserInfo, setNewUserInfo] = useState<NewUser>(newUserInitialValues)

  if (selectedUserRole) {
    console.log('selectedUserRole', selectedUserRole)
    return (
      <Page>
        <div className="p-8">
          <div
            onClick={() => {
              setSelectedUserRole(undefined)
            }}
          >
            Tillbaka
          </div>
          <InformationAboutParent
            onlyRelation={true}
            onChangeRelation={(value) => setRelationAndAuthority(value)}
            relationAndAuthority={relationAndAuthority}
            otherText={otherText}
            editFreeTextValue={(value: string) => setOtherText(value)}
            onDone={() => {
              setShowSummary(true)
            }}
          />
        </div>
        {showSummary && (
          <ModalRegular
            onClose={() => {
              setShowSummary(false)
            }}
            isOpen={true}
            className="max-w-lg"
          >
            <div className="py-4 px-8">
              {selectedKid && (
                <KidListCardHorizontal
                  kid={selectedKid}
                  showDepartmentName
                  naked
                />
              )}
              <div className="flex flex-col justify-center p-8 gap-y-4">
                <div className="font-bold text-xl text-center">
                  !!Sammanfattning
                </div>
                <div className="flex flex-col p-4 border border-gray-400 rounded-2xl gap-y-1 text-sm">
                  {selectedUserRole && (
                    <>
                      <div>
                        <FontAwesomeIcon
                          className="mr-2 w-[20px]"
                          icon={faUser}
                          color={'#565656'}
                        />
                        {selectedUserRole.user?.firstName +
                          ' ' +
                          selectedUserRole.user?.lastName}
                        {selectedUserRole.user?.user_nin.attributes.nin
                          ? ' (' +
                            selectedUserRole.user?.user_nin.attributes.nin +
                            ')'
                          : null}
                      </div>
                      <div>
                        <FontAwesomeIcon
                          className="mr-2 w-[20px]"
                          icon={faPhone}
                          color={'#565656'}
                        />
                        {selectedUserRole.user?.user_extra.attributes
                          .primary_phone ?? null}
                      </div>
                      <div>
                        <FontAwesomeIcon
                          className="mr-2 w-[20px]"
                          icon={faAt}
                          color={'#565656'}
                        />
                        {selectedUserRole.user?.user_extra.attributes.email ??
                          null}
                      </div>
                      {selectedUserRole.user?.user_extra.attributes
                        .street_address ? (
                        <>
                          <div>
                            <FontAwesomeIcon
                              className="mr-2 w-[20px]"
                              icon={faLocationDot}
                              color={'#565656'}
                            />
                            {
                              selectedUserRole.user?.user_extra.attributes
                                .street_address
                            }
                          </div>
                          <div>
                            <FontAwesomeIcon
                              className="mr-2 w-[20px] opacity-0"
                              icon={faLocationDot}
                              color={'#565656'}
                            />
                            {selectedUserRole.user?.user_extra.attributes
                              .zip_code +
                              ' ' +
                              selectedUserRole.user?.user_extra.attributes.city}
                          </div>
                        </>
                      ) : null}
                    </>
                  )}
                </div>
                <div className="flex justify-center mt-4">
                  <Button
                    variant="callToActionBlue"
                    label={t('admin.invite')}
                    onClick={() => {
                      if (selectedUserRole) {
                        if (schoolId && selectedKid && selectedUserRole.user) {
                          inviteExistingGuardian(
                            schoolId,
                            selectedKid.id,
                            selectedUserRole.user.id,
                            relationAndAuthority.addDocuments,
                          )
                        }
                      }
                    }}
                  />
                </div>
              </div>
            </div>
          </ModalRegular>
        )}
      </Page>
    )
  }

  if (chosenInviteType) {
    return (
      <Page>
        <div className="p-8">
          <div
            onClick={() => {
              setChosenInviteType(undefined)
            }}
          >
            Tillbaka
          </div>
          {chosenInviteType === 'new' ? (
            <InformationAboutParent
              onlyRelation={false}
              onChangeRelation={(value) => setRelationAndAuthority(value)}
              relationAndAuthority={relationAndAuthority}
              otherText={otherText}
              editFreeTextValue={(value: string) => setOtherText(value)}
              onDone={() => {
                setShowSummary(true)
              }}
              newUserInfo={newUserInfo}
              setNewUserInfo={(userInfo: NewUser) => setNewUserInfo(userInfo)}
            />
          ) : (
            <ChooseExistingUser
              onSelectedUserRole={(userRole) => {
                setSelectedUserRole(userRole)
              }}
            />
          )}
        </div>
      </Page>
    )
  }

  if (selectedKid) {
    return (
      <ChooseOrCreateParent
        onChoose={(value) => {
          setChosenInviteType(value)
        }}
        onBack={() => {
          setSelectedKid(undefined)
        }}
        kid={selectedKid}
      />
    )
  }

  return (
    <Page>
      <div className="p-8">
        {loading && <Loading />}
        {departments?.map((department, index) => (
          <>
            <h2 className="font-bold my-6 text-xl">{department.klassName}</h2>
            <div className="grid grid-cols-3 gap-4">
              {kids
                ?.filter(
                  (kid) =>
                    kid?.attributes?.departmentPointer?.id === department?.id,
                )
                .map((kid) => (
                  <KidListCardHorizontal
                    kid={kid}
                    onClick={() => {
                      setSelectedKid(kid)
                    }}
                  />
                ))}
            </div>
          </>
        ))}
      </div>
    </Page>
  )
})
