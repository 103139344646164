import { useContext, useEffect, useState } from 'react'
import Page from '../../../../../components/Page'
import { t } from 'i18next'
import { NavigationStoreContext } from '../../../../../store/navigationStore'
import { KidsStoreContext } from '../../../../../store/kidsStore'
import { UserStoreContext } from '../../../../../store/userStore'
import { DepartmentStoreContext } from '../../../../../store/departmentStore'
import { getImageOrPlaceHolder } from '../../../../../utils/parseUtils'
import { Kid } from '../../../../../Models/Kid'
import { useParams } from 'react-router-dom'
import { Loading } from '../../../../../components/Loading'
import React from 'react'
import KidListCardHorizontal from '../../../../../components/kid/KidListCardHorizontal'
import { observer } from 'mobx-react'
import { Button } from '../../../../../components/Button'
import { USER_ROLE_STATUS } from '../../../../../Enums/TyraEnums'
interface Props {
  onChoose: (value: 'existing' | 'new') => void
  onBack: () => void
  kid: Kid
}
export const ChooseOrCreateParent: React.FC<Props> = observer(
  ({ onChoose, onBack, kid }) => {
    const [showInviteModal, setShowInviteModal] = useState(false)
    const { fetchKids, kids } = useContext(KidsStoreContext)
    const { currentUserRole, fetchUserRolesToInviteToKid, fetchNewUserRoles } =
      useContext(UserStoreContext)
    const { departments, fetchDepartments } = useContext(DepartmentStoreContext)
    const { setTitle } = useContext(NavigationStoreContext)

    const { schoolId } = useParams()
    const [loading, setLoading] = useState(false)

    useEffect(() => {
      setTitle(t('admin.inviteParent'))
    }, [t])

    return (
      <Page>
        <div className="p-8">
          {loading && <Loading />}
          <div
            onClick={() => {
              onBack()
            }}
          >
            Tillbaka
          </div>
          <KidListCardHorizontal
            kid={kid}
            showDepartmentName
            naked
            makeCenter
          />
          <div className="flex justify-center mt-6">
            <div className="flex flex-col justify-center gap-y-6 max-w-[300px]">
              <div className="flex text-xl font-bold justify-center">
                !!Bjud in
              </div>
              <div className="flex flex-col gap-y-4">
                <Button
                  onClick={() => onChoose('existing')}
                  variant="borderGreen"
                  label={'!!Befintlig användare'}
                />
                <div className="text-sm text-center">
                  !!Måste vara en anvädare som redan har koppling till er
                  förskola
                </div>
              </div>
              <div className="flex text-xl font-bold justify-center">
                !!ELLER
              </div>
              <Button
                onClick={() => onChoose('new')}
                variant="fullBlue"
                label={'!!Ny användare'}
              />
            </div>
          </div>
          <div className="flex text-xl font-bold mt-8">!!Redan inbjudna</div>
          <div className="grid grid-cols-3">
            {kid.relatives.map((rel) => {
              console.log('rel', rel)
              if (rel.status === USER_ROLE_STATUS.ACTIVE) {
                return (
                  <div>
                    {rel.user?.firstName + ' ' + rel.user?.lastName} !!Aktiv
                  </div>
                )
              } else if (rel.status === USER_ROLE_STATUS.PENDING) {
                return <div>{rel.invite_note} !! Väntar godkännande</div>
              } else if (rel.status === USER_ROLE_STATUS.NEW) {
                return (
                  <div>
                    {rel.user?.firstName
                      ? rel.user.firstName + ' ' + rel.user.lastName
                      : rel.invite_note}{' '}
                    !! Inbjuden
                  </div>
                )
              }
            })}
          </div>
        </div>
      </Page>
    )
  },
)
